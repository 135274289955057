import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { twMerge } from 'tailwind-merge';

const RequestAmountField = ({ name, value, onChange, min = 12500, max = 200000, step = 500, className, ...properties }) => {
    const mask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
        integerLimit: 6,
        allowNegative: false,
        allowLeadingZeroes: false,
    });

    const handleChange = (event) => {
        let {
            target: { value },
        } = event;

        value = value.replaceAll('.', '').replaceAll(',', '.');
        onChange({ target: { name, value } });
    };

    return (
        <div className={twMerge('flex items-center gap-1 text-black p-4 w-full bg-white border border-solid border rounded-lg border-grey-200 focus:border-primary-500 bg-white focus:outline-none', className)} {...properties}>
            <p className={'text-5xl font-bold'}>&euro;</p>

            <MaskedInput className={'w-full rounded-lg text-5xl font-bold'} name={name} value={value} mask={mask} onChange={handleChange} />
        </div>
    );
};

export default RequestAmountField;
