import { motion } from 'framer-motion';
import * as Motioner from '../../../utils/MotionerUtil.js';
import { twMerge } from 'tailwind-merge';
import { useCallback, useMemo } from 'react';

const SwitchField = ({ name, onChange, value, className, style, ...properties }) => {
    const handleSwitchFieldClick = useCallback(() => {}, [value]);

    const handleSwitchClick = () => {
        onChange({ target: { name, value: !value } });
    };

    const uniqueLayoutId = useMemo(() => `switch-active-${name}-${Math.random().toString(36).substr(2, 9)}`, [name]);

    return (
        <motion.div className={twMerge('flex items-center h-12 w-fit rounded-md cursor-pointer border-solid border border-grey-200 bg-white p-1 overflow-hidden', className)} onClick={handleSwitchClick} {...Motioner.click} {...properties}>
            <div className={'relative flex h-full w-12 items-center justify-center px-4'}>
                {value && <motion.div className={`absolute left-0 top-0 size-full rounded-md bg-secondary-500`} layoutId={uniqueLayoutId} style={style} initial={false} />}

                <p className={'relative'}>Ja</p>
            </div>

            <div className={'relative flex h-full w-12 items-center justify-center px-4'}>
                {!value && <motion.div className={`absolute left-0 top-0 size-full rounded-md bg-secondary-500`} layoutId={uniqueLayoutId} style={style} initial={false} />}

                <p className={'relative'}>Nee</p>
            </div>
        </motion.div>
    );
};

export default SwitchField;
